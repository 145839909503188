import chatSelect from "../chatSelect";

var initial = {
    search: "",
    chats: [],
}

function ChatSelect (state = initial, action) {
    if (action.type === 'CHAT_SELECT_SEARCH') {
        return {
            ...state,
            search: action.search
        }
    } else if (action.type === 'CHAT_SELECT_LIST') {
        return {
            ...state,
            chats: action.chats
        }
    }
    return state;
}

export default ChatSelect