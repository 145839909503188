import React, { Component } from "react"
import { connect } from 'react-redux';
import Helper from'./helper.js';

class MessageList extends Component {

	constructor(props) {
        super(props)
        
        this.messagesRef= React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
		if(nextProps.messageList.answer_text !== "" && this.props.messageList !== nextProps.messageList.answer_text) return false;
		return true;
	}
	
    componentDidMount() {

    }

    componentWillUnmount() {

    }

    componentDidUpdate() {
        const scrollHeight = this.messagesRef.current.scrollHeight;
        const height = this.messagesRef.current.clientHeight;
        const maxScrollTop = scrollHeight - height;
        this.messagesRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    setClick(text) {
        if(text === "") return;
        if(this.props.messageList.answer_text === text) {
            this.props.setAnswerText(text)
            this.props.setClick("");
        } else {
            this.props.setClick(text);
        }
    }
	
    render() {
        var days = {};
        return (
            <div ref={this.messagesRef} className="messages">
                {
                    this.props.main.messages.map((message, i) => {

                        let _day = Helper.getDate(message.date);
                        if(days[_day] === undefined) days[_day] = i;
                        let date = new Date();
                        date.setTime(_day);

                        let month = Helper.months[date.getMonth()]
                        let day = date.getDate()

                        return (
                            <div key={i}>
                                {
                                    days[_day] === i ? <div className="message_header">{day + " " + month}</div> : ""
                                }
                                <div onClick={(e)=> { this.setClick(message.text) }} className={"message" + (this.props.main.hash === message.sender ? " right" : "")}>
                                    <div className="message_text">
										{
											message.answer_text !== "" ? <div className="bs-callout">{message.answer_text}</div> : ""
										}
										<span dangerouslySetInnerHTML={{__html: Helper.getMessage(message.text)}}></span>
										{
											message.file !== "" ? <a className="btn btn-light btn-sm" target="_blank" href={message.file}><i className="fas fa-file"></i> Скачать</a> : ""
										}
									</div>
                                    <div className="message_bottom">
                                        <div className="message_date">{Helper.getTime(message.date)}</div>
                                        {
                                            this.props.main.hash === message.sender ? <div className={message.status === 0 ? "message_status zero" : "message_status one"}></div> : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}



export default connect(
    state => ({
        main: state.Main,
        messageList: state.MessageList
    }),
    dispatch => ({
        setClick: (answer_text) => {
            dispatch({ type: 'MESSAGE_LIST_ANSWER_TEXT', answer_text});
        },
        setAnswerText: (answer_text) => {
            dispatch({ type: 'MAIN_ANSWER_TEXT', answer_text});
        }
    })
)(MessageList);

