var initial = {
    answer_text: "",
}

function MessageList (state = initial, action) {
    if (action.type === 'MESSAGE_LIST_ANSWER_TEXT') {
        return {
            ...state,
            answer_text: action.answer_text
        }
    }
    return state;
}

export default MessageList