import React, { Component } from "react"
import { connect } from 'react-redux';
import Helper from'./helper.js';


class MessageForm extends Component {

	constructor(props) {
        super(props)
        
        this.messageRef= React.createRef();
        this.fileRef= React.createRef();
    }
	
   componentDidMount() {
        this.messageRef.current.addEventListener('keyup', this.onKeyUp.bind(this));
        this.messageRef.current.addEventListener('keydown', this.onKeyDown.bind(this));
    }

    componentWillUnmount() {
        this.messageRef.current.removeEventListener('keyup', this.onKeyUp);
        this.messageRef.current.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyUp(event) {
        if(event.key === "Shift") {
            console.log("event");
            this.props.setKeyUp(false)
        }
        if(event.key === "Enter" && this.props.messageForm.keyUp === false) {
            event.preventDefault();
            if(this.messageRef.current.value !== "") window.chatAPI.socket.emit('user:message', this.props.main.channel, this.messageRef.current.value, this.props.main.answer_text);
            this.messageRef.current.value = ""
            this.messageRef.current.style.height = "30px"
            this.props.setAnswerText("")
        }
        if(this.messageRef.current.scrollTop > 0 && this.messageRef.current.clientHeight < 300){
            this.messageRef.current.style.height = this.messageRef.current.scrollHeight + "px";
        }
    }

    onKeyDown(event) {
        if(event.key === "Shift" && this.props.messageForm.keyUp === false) {
            console.log("event");
            this.props.setKeyUp(true)
        }
        if(event.key === "Enter" && this.props.messageForm.keyUp === false) {
            event.preventDefault();
            if(this.messageRef.current.value !== "") window.chatAPI.socket.emit('user:message', this.props.main.channel, this.messageRef.current.value, this.props.main.answer_text);
            this.messageRef.current.value = ""
            this.messageRef.current.style.height = "30px"
            this.props.setAnswerText("")
        }
    }
    
    handleFile(e) {
        e.preventDefault();
        this.fileRef.current.click();
    }


    render() {
        return (
			<div className='message_form'>			
				{
					this.props.main.answer_text !== "" ? 
					<div className="bs-callout">
						<span dangerouslySetInnerHTML={{__html: Helper.getMessage(this.props.main.answer_text)}}></span>
						<button onClick={()=> this.props.setAnswerText("")} className="btn btn-default btn-sm">123</button>
					</div> : ""
				}
				<div className='message_input'>
					<a href="#upload-file" onClick={this.handleFile.bind(this)} className="btn btn-default"  disabled={this.props.messageForm.loading}>
                    {
                        this.props.messageForm.loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <i className="fas fa-file"></i>
                    }
					</a>
                    <textarea ref={this.messageRef} placeholder="Сообщение..."></textarea>
                    <input ref={this.fileRef} type="file"/>
				</div>
			</div>
        )
    }
}


export default connect(
    state => ({
        main: state.Main,
        messageForm: state.MessageForm,
    }),
        dispatch => ({
        setKeyUp: (keyUp) => {
            dispatch({ type: 'MESSAGE_FORM_KEY_UP', keyUp});
        },
        setAnswerText: (answer_text) => {
            dispatch({ type: 'MAIN_ANSWER_TEXT', answer_text});
        }
    })
)(MessageForm);

