var initial = {
    hash: "194f3cb628fa3add4265a16cc96f2620",
    sessid: "test",
    answer_text: "",
    channel: "", 
    user_name: "",
    chats: [],
    moder: false,
    email: "", 
    company: "", 
    day: 0,
    online: null,
    show: true,
    messages: []
}

function Main (state = initial, action) {
    if (action.type === 'MAIN_AUTH') {
        return {
            ...state,
            hash: action.hash, 
            sessid: action.sessid, 
            channel: action.channel, 
            user_name: action.user_name, 
            moder: action.moder
        }
    } else if (action.type === 'MAIN_CHATS') {
        return {
            ...state,
            chats: action.chats
        }
    } else if (action.type === 'MAIN_SHOW') {
        return {
            ...state,
            show: action.show
        }
    } else if (action.type === 'MAIN_SELECT_CHAT') {
        return {
            ...state,
            channel: action.channel,
            user_name: action.user_name,
            email: action.email, 
            company: action.company, 
            day: action.day,
            online: null
        }
    } else if (action.type === 'MAIN_ADD_MESSAGE') {
        let messages = state.messages
        messages.push(action.message)
        return {
            ...state,
            messages

        }
    } else if (action.type === 'MAIN_MESSAGES') {
        return {
            ...state,
            messages: action.messages
        }
    } else if (action.type === 'MAIN_ONLINE') {
        return {
            ...state,
            online: action.online
        }
    } else if (action.type === 'MAIN_ANSWER_TEXT') {
        return {
            ...state,
            answer_text: action.answer_text
        }
    } 
    return state;
}

export default Main