import React, { Component } from "react"
import { connect } from 'react-redux';
import Helper from'./helper.js';

import MessageForm from './messageForm';
import MessageList from './messageList';

class ChatForm extends Component {

	constructor(props) {
        super(props)
        
    }
	
    componentDidMount() {

    }

    componentWillUnmount() {
    }
    
    getStatus(status) {
		if(this.props.main.online === 0) return "В сети";
		if(this.props.main.online !== null) {
			let now = new Date();
			let date = new Date(this.props.main.online)
			if(date.getYear() === now.getYear() && date.getMonth() === now.getMonth() && date.getDate() === now.getDate()) {
				return "Был(а) в сети " + Helper.getTime(date.getTime()) + " сегодня";
			} else if(date.getYear() === now.getYear() && date.getMonth() === now.getMonth() && date.getDate() === now.getDate()-1) {
				return "Был(а) в сети " + Helper.getTime(date.getTime()) + " вчера";
			} else {
				let month = Helper.months[date.getMonth()]
				let day = date.getDate()
				return "Был(а) в сети " + day + " " + month + " " + Helper.getTime(date.getTime());
			}
		}
		return "Не в сети";
	}
    
    render() {
        return (
            <div className="message_block">
                <h2>{this.props.main.user_name}</h2>				
                <h4>{this.getStatus(this.props.main.online)}</h4>
				{
					this.props.main.moder ?
					
						<div className="message_description">
							<span>{this.props.main.company}</span>
							<a href={"mailto:" + this.props.main.email}>{this.props.main.email}</a>
							<span>Доступ: {this.props.main.day} дней</span>
						</div> : "" 
				}
                <MessageList/>
                <MessageForm/>
            </div>
        )
    }
}



export default connect(
    state => ({
        main: state.Main,
    })
)(ChatForm);

