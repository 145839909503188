var initial = {
    loading: false,
    keyUp: false
}

function MessageForm (state = initial, action) {
    if (action.type === 'MESSAGE_FORM_KEY_UP') {
        return {
            ...state,
            keyUp: action.keyUp
        }
    }
    return state;
}

export default MessageForm