import React, { Component } from "react"
import { connect } from 'react-redux';
import Helper from'./helper.js';

import client from './img/client.png'
import support_female from './img/support_female.png'
import support_male from './img/support_male.png'

class ChatSelect extends Component {

	constructor(props) {
        super(props)
        
    }
	
    componentDidMount() {

    }

    
    componentWillUnmount() {

    }
    
    onChange(e) {
        let text = e.target.value.toLowerCase();
        this.props.setSearch(text)
	
		var chats = []
		for(var i = 0; i <  this.props.main.chats.length; i++) {
		  if( this.props.main.chats[i].user_name.toLowerCase().indexOf(text) !== -1 
			||  this.props.main.chats[i].data.toLowerCase().indexOf(text) !== -1
			||  this.props.main.chats[i].company.toLowerCase().indexOf(text) !== -1
			||  this.props.main.chats[i].email.toLowerCase().indexOf(text) !== -1) {
			chats.push( this.props.main.chats[i]);
		  }
		}
		this.props.setChats(chats)
    }
    
    render() {
        return (
            <div className="chats_list">
                {
					this.props.main.moder ?
					<div className="chats_search">
						<input onChange={this.onChange.bind(this)} value={this.props.chatSelect.search} className="form-control" type="text" placeholder="Поиск пользователя ..."/>
					</div> : ""
				}
                <div className="chats">
                    {
                        (this.props.chatSelect.search !== "" ? this.props.chatSelect.chats : this.props.main.chats).map((chat, i) => {
                            return (
                                <a href="#select-chat" key={i} onClick={()=>{this.props.setChat(chat)}} className="chat">
                                    <div className="chat_block">
                                        <div className="chat_image"><img alt={chat.sex} src={chat.sex === "support_female" ? support_female : chat.sex === "support_male" ? support_male : client}/></div>
                                        <div className="chat_content">
                                            <div className="chat_header">
                                                <div className="chat_name">{chat.user_name}</div>
                                                {
                                                    chat.message === "" && chat.file === false ? "" :
                                                        (chat.status === 0 && chat.sender !== this.props.main.hash ?
                                                            <div className="chat_status"><div className="new"></div></div>
                                                            : chat.sender === this.props.main.hash ? "" : "")
                                                }
                                                {
                                                    chat.message === "" && chat.file === false ? "" : <div className="chat_date">{Helper.getTime(chat.date)}</div>
                                                }
                                            </div>
                                            {
                                                chat.message === "" && chat.file === false ? "" :
                                                    <div className={chat.sender === this.props.main.hash ? "chat_message blue" : "chat_message"}>{" " + Helper.getTrunc(chat.message) + " "}{chat.file === true ? <span><i className="fas fa-file"></i> Файл </span>   : ""}</div>
                                            }
                                        </div>
                                    </div>
                                </a>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}



export default connect(
    state => ({
        main: state.Main,
        chatSelect: state.ChatSelect,
    }),
    dispatch => ({
        setChat(client) {
            let {channel, user_name, email, company, day} = client;
            window.chatAPI.socket.emit('user:messages', channel)
            dispatch({ type: 'MAIN_SELECT_CHAT', channel, user_name, email, company, day});
        },
        setSearch(search) {
            dispatch({ type: 'CHAT_SELECT_SEARCH', search});
        },
        setChats(chats) {
            dispatch({ type: 'CHAT_SELECT_LIST', chats});
        }
    })
)(ChatSelect);

