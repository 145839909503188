import React, { Component } from "react"
import { connect } from 'react-redux';

import ChatSelect from './chatSelect';
import ChatForm from './chatForm';


class ChatApp extends Component {

	constructor(props) {
        super(props)
        
        window.chatAPI = {}
        window.chatAPI.show = this.props.setShow
        window.chatAPI.socket = window.io.connect("https://chat.headschool.ru");
        window.chatAPI.auth = this.sendAuth
    }
	
   componentDidMount() {
        window.chatAPI.socket.on('user:count', this.userCount.bind(this));
        window.chatAPI.socket.on('user:auth', this.userAuth.bind(this));
        window.chatAPI.socket.on('user:chats', this.userChats.bind(this));
        window.chatAPI.socket.on('connection', this.connection.bind(this));
        window.chatAPI.socket.on('disconnect', this.disconnect.bind(this));
        window.chatAPI.socket.on('reconnect', this.reconnect.bind(this));
        window.chatAPI.socket.on('send:message', this.messageRecieve.bind(this));
        window.chatAPI.socket.on('user:messages', this.props.setMessages);
        window.chatAPI.socket.on('user:online', this.props.setOnline);
    }

    componentWillUnmount() {
        window.chatAPI.socket.removeListener('user:count', this.userCount);
        window.chatAPI.socket.removeListener('user:auth', this.userAuth);
        window.chatAPI.socket.removeListener('user:chats', this.userChats);
        window.chatAPI.socket.removeListener('disconnect', this.disconnect);
        window.chatAPI.socket.removeListener('connection', this.connection);
        window.chatAPI.socket.removeListener('reconnect', this.reconnect);
        window.chatAPI.socket.removeListener('send:message', this.messageRecieve);
        window.chatAPI.socket.removeListener('user:messages', this.props.setMessages);
        window.chatAPI.socket.removeListener('user:online', this.props.setOnline);
    }

    connection(user_id) {
        console.log('Connect: ' + user_id);
    }

    sendAuth(hash, sessid) {
        window.chatAPI.socket.emit('auth:user', hash, sessid)
    }

    setOnline(hash, online) {
		let [client, manager] = this.props.main.channel.split("_");
		if(hash == this.props.main.hash) return;
		if(hash != client && hash != manager) return;
		this.setState({ online });
    }

    userAuth(data) {
        let {hash, sessid, channel, user_name, moder} = data;
        this.props.setAuth(hash, sessid, channel, user_name, moder)
        if(window.chatAPI.event !== undefined) window.chatAPI.event({status:"auth"})
    }

    messageRecieve(message) {
        if(message.sender !== this.props.main.hash && this.props.main.channel !== "") {
            window.chatAPI.emit('send:status', this.props.main.channel);
        }
        this.props.addMessage(message)
    }

    disconnect() {
        console.log("Disconnect");
        window.chatAPI.socket.io.reconnect();
    }

    reconnect() {
        console.log("Reconnect");
        window.chatAPI.socket.emit('auth:user', this.props.main.hash, this.props.main.sessid);
    }

    userChats(data) {
        let chats = []
        for (let channel in data) {
            let _chat = data[channel]
            _chat.channel = channel;
            chats.push(_chat)
        }

        chats.sort(function(chat1,chat2) {
            if (chat1.date > chat2.date) return -1;
            if (chat1.date < chat2.date) return 1;
            return 0;
        });

        this.props.setChats(chats);
    }


    userCount(count) {
        if(window.chatAPI.event !== undefined) window.chatAPI.event({message:count})
    }
	
    closeButton(e) {
        e.preventDefault();
        if(window.chatAPI.event !== undefined) window.chatAPI.event({status:"close"})
        this.props.setShow(false)
    }

    render() {
        return (
            this.props.main.show === true ? 
            <div>
                <button onClick={this.closeButton.bind(this)} className="close btn btn-default btn-sm"><i className="fas fa-times"></i></button>
                {
                    <div className="chats_module">
                        <ChatSelect/>
                        {
                            this.props.main.channel !== "" ? <ChatForm/> : null
                        }
                    </div>
                }
            </div> : null
        )
    }
}



export default connect(
    state => ({
        main: state.Main,
    }),
    dispatch => ({
        setAuth: (hash, sessid, channel, user_name, moder) => {
            dispatch({ type: 'MAIN_AUTH', hash, sessid, channel, user_name, moder});
        },
        setChats: (chats) => {
            dispatch({ type: 'MAIN_CHATS', chats});
        },
        setShow: (show) => {
            dispatch({ type: 'MAIN_SHOW', show});
        },
        setOnline: (online) => {
            dispatch({ type: 'MAIN_ONLINE', online});
        },
        addMessage: (message) => {
            dispatch({ type: 'MAIN_ADD_MESSAGE', message});
        },
        setMessages: (messages) => {
            dispatch({ type: 'MAIN_MESSAGES', messages});
        }
    })
)(ChatApp);

