import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './css/style.css';

import ChatApp from './chatApp';
import Helper from'./helper.js';

ReactDOM.render(
  <Provider store={Helper.store}>
    <ChatApp />
  </Provider>,
  document.getElementById('chat')
);

