import { createStore } from 'redux';
import reducer from './reducers';

const Helper = {
    store: createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()),
    months: ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],
    getTime: (time) => {
        var date = new Date();
        date.setTime(time);
    
        var hour = date.getHours();
        hour = hour < 10 ? "0" + hour : hour;
    
        var min = date.getMinutes();
        min = min < 10 ? "0" + min : min;
    
        return hour + ":" + min;
    
    },
    getDate: (time) => {
        var date = new Date();
        date.setTime(time);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setHours(0);
        date.setMilliseconds(0);
    
        return date.getTime();
    },
    getTrunc: (text) => {
        if (text.length <= 60) { return text; }
        text = text.substr(0, 60-1);
        return text.substr(0, text.lastIndexOf(' '));
    },
    getMessage: (text) => {
        return text.replace(
            /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g,
            '<a target="_blank" href="$1">$1</a>'
        );
    }
}

export default Helper  