import { combineReducers } from 'redux';

import Main from './main';
import MessageForm from './messageForm';
import MessageList from './messageList';
import ChatSelect from './chatSelect';

export default combineReducers({
  Main,
  MessageForm,
  MessageList,
  ChatSelect
});
